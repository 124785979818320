import React, { useContext, useEffect, useRef, useState, lazy, Suspense } from 'react'
import intl from 'react-intl-universal'
import stickybits from 'stickybits'
import { produce } from 'immer'
import Tippy from '@tippyjs/react'
import toast from 'react-hot-toast'
import { omit, pickBy } from 'ramda'
import { useMutation, useQuery } from '@apollo/client'
import { Transition, TransitionChild } from '@headlessui/react'
import { getChaptersForCourseQuery, GetChaptersForCourseRes, GetChaptersForCourseVars } from 'graphql/queries/chapter/getChaptersForCourse'
import { AppState, getAppStateQuery } from 'graphql/queries/app/getAppState'
import { GetCurrentSubscriptionRes, getCurrentSubscriptionQuery, GetCurrentSubscriptionVars, getUserAndActiveCourseQuery, GetUserAndActiveCourseResponse, getUserMetadata, GetUserMetadataRes } from 'graphql/queries/user/getUser'
import { getEngagementsForSubscriptionQuery, GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars } from 'graphql/queries/user/getEngagementsForSubscription'
import { subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { Chapter as ChapterType } from 'graphql/schemas/chapter/Chapter'
import { getStudyForSubscriptionQuery, GQLStudyForSubscriptionResponse, GQLStudyForSubscriptionVariables } from 'graphql/queries/user/getStudyForSubscription'
import { Action as ActionType, allAssessmentsCompleted, checkCertificate } from 'graphql/schemas/action/Action'
import { updateChapterMutation } from 'graphql/mutations/chapter/updateChapter'
import ActionList from 'components/chapter/ActionList'
import GnowbefyButton from 'components/navbar/buttons/GnowbefyButton'
import NewActionModal from 'components/editor/ActionNew'

const Action = lazy(() => import(/* webpackChunkName: "Action" */ 'components/Action'))
const Group = lazy(() => import(/* webpackChunkName: "Group" */ 'pages/Group'))
const Workbook = lazy(() => import(/* webpackChunkName: "Workbook" */ 'pages/Workbook'))
const Goodiebag = lazy(() => import(/* webpackChunkName: "Goodiebag" */ 'components/editor/resource/Goodiebag'))
const Notes = lazy(() => import(/* webpackChunkName: "Notes" */ 'pages/Notes'))
const Members = lazy(() => import(/* webpackChunkName: "Members" */ 'components/group/Members'))
const PostLikes = lazy(() => import(/* webpackChunkName: "PostLikes" */ 'components/activity/PostLikes'))
const Feedback = lazy(() => import(/* webpackChunkName: "Feedback" */ 'pages/Feedback'))
const Overview = lazy(() => import(/* webpackChunkName: "Overview" */ 'pages/Progress'))

import { SidebarMenu } from 'components/chapter/SidebarMenu'
import FullscreenLoader from 'components/utils/FullscreenLoader'
import { HorizontalMenu, MobileHorizontalMenu } from 'components/home/HorizontalMenu'
import { ChapterOrder } from 'components/chapter/Order'
import { ChapterTitle } from 'components/chapter/Title'
import { ChapterImage } from 'components/chapter/Cover'
import { ChapterDescription } from 'components/chapter/Description'
import { MiniLoader } from 'components/utils/MiniLoader'
import { showModal, showModal2 } from 'components/utils/CustomModal'
import { Additional } from 'components/editor/chapter/Details'
import { chapterViewed } from 'actions/chapters_actions'
import { MoreButton } from 'components/home/MoreButton'
import { Share } from 'components/group/Share'
import { createChapter, CreateNewChapterButton } from 'components/navbar/chapter-list/ChapterListContainer'
import { resetUserStudy } from 'actions/courses_actions'
import { AssessmentsCompletedDialog, NoSessionAlert } from 'components/Chapter'
import { ManualReviewCourseCompleted } from 'components/chapter/ManualReviewsBanner'
import { debounce, getDaysFromEpoch2, getMeta, isColorDark, isOnline, isRtlLanguage } from 'utils/functions'
import { localeFormat, slog } from 'utils/dateFunctions'
import { client, updateAppCache } from 'app'
import { getMcStatsForCourseQuery } from 'graphql/queries/action/getMcStatsForCourse'
import { listenUserSubscriptionChangesListener, UserSubscriptionChangesRes } from 'graphql/subscriptions/user/listenUserSubscriptionChanges'
import { ProgressReviewUpdated, UserProgress, userProgressFactory } from 'graphql/schemas/user/UserProgress'
import { studyReviewFactory } from 'graphql/fragments/Reviews'
import { Typename } from 'graphql/schemas/app/State'
import { ProgressReview } from 'graphql/schemas/review/Review'
import { StudyViewedUpdated, StudyAnswerUpdated, StudyCompletionUpdated, UserStudy, userStudyFactory, StudyTimestampsUpdated, StudyShared, StudyShareLiked, StudyShareUnliked, StudyShareCommented, StudyShareCommentRemoved, StudyRemoved, StudyReviewUpdated } from 'graphql/schemas/user/UserStudy'
import { listenChaptersForCourseChangesListener, ListenChaptersForCourseChangesRes } from 'graphql/subscriptions/chapter/listenChaptersForCourseChanges'
import { ListenEngagementsForSubscriptionChangesRes, listenEngagementsForSubscriptionChangesSubscription } from 'graphql/subscriptions/course/listenEngagementsForSubscriptionChanges'
import { listenMcStatsForCourseChangesSubscription } from 'graphql/subscriptions/course/listenMcStatsForCourseChanges'
import { listenStudyForSubscriptionChangesSubscription, StudyForSubscriptionChangesRes } from 'graphql/subscriptions/user/listenStudyForSubscriptionChanges'
import { deleteChapterMutation, DeleteChapterRes, DeleteChapterVars } from 'graphql/mutations/chapter/deleteChapter'
import { Button } from 'components/utils/Button'
import { changeCurrentCourse } from 'actions/users_actions'
import { ScormProgram } from 'components/chapter/Scorm'
import { AssessmentCounter2 } from 'components/chapter/AssessmentsCounter'
import { trackButton, trackButtonEnhanced } from 'utils/track'
import { AssessmentsSupervisor, AssessmentsSupervisorCtx } from 'components/course/AssessmentsSupervisor'
import { assertNever, augmentStudy } from 'utils/utils'
import * as logger from 'logger'
import { MiniStat } from 'pages/Home'
import { getSignedValue } from 'actions/files'
import CmdK from 'components/CmdK'
import { ChapterList } from 'components/learn/ChapterList'
import { NextChapterButton } from 'components/chapter/NextChapterButton'
import { NewActionButton } from 'components/chapter/NewActionButton'
import { MiniCourseDetails } from 'components/learn/MiniCourseDetails'
import { isCourseExpired } from 'graphql/schemas/course/Course'
import CheckUpdate from 'components/utils/CheckUpdate'
import { showDrawer } from 'components/utils/Drawer'
import PublishDialog from 'components/course/Publish'
import { isAgent as isAgente } from 'graphql/schemas/user/User'
import { showAlert } from 'components/utils/Alert'
import { UpsellModal } from 'components/modals/UpsellModal'
import Banner from 'components/utils/Banner'
import { GetUserNotificationBannersRes, getUserNotificationBannersQuery } from 'graphql/queries/banner/getUserNotificationBanners'
import { getOrganizationsQuery, GetOrganizationsRes, GetOrganizationsVars } from 'graphql/queries/organization/getOrganziations'
import { matchPath, matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { history } from 'utils/history'
import BackButton from 'components/navbar/buttons/BackButton'
import CalendarDay from 'components/icons/CalendarDays'
import CheckIcon from 'components/icons/Check'
import { useWhiteLabel } from 'context/whiteLabel'
import { ActionItemLoader, ActionItemPlaceholder } from 'components/chapter/ActionItem'
import MagicCreator from 'components/MagicCreator'
import MagicStarsIcon from 'components/icons/MagicStars'

const routePatterns = [
  '/learn',
  '/learn/notes',
  '/learn/group',
  '/learn/share/:shareId',
  '/learn/likes/:chapterId',
  '/learn/likes/:chapterId/:actionId',
  '/learn/likes/:chapterId/:actionId/:userId',
  '/learn/shares/:memberId',
  '/learn/members',
  '/learn/workbook',
  '/learn/progress',
  '/learn/resources',
  '/learn/feedback',
  '/learn/:chapterId',
  '/learn/:chapterId/:actionId',
  '/learn/:chapterId/:actionId/r',
  '/session',
  '/session/:chapterId',
  '/group',
  '/notes',
  '/workbook',
  '/workbook/progress',
];

const routesPatterns = [
  {path: '/learn'},
  {path: '/learn/notes'},
  {path: '/learn/group'},
  {path: '/learn/share/:shareId'},
  {path: '/learn/likes/:chapterId'},
  {path: '/learn/likes/:chapterId/:actionId'},
  {path: '/learn/likes/:chapterId/:actionId/:userId'},
  {path: '/learn/shares/:memberId'},
  {path: '/learn/members'},
  {path: '/learn/workbook'},
  {path: '/learn/progress'},
  {path: '/learn/resources'},
  {path: '/learn/feedback'},
  {path: '/learn/:chapterId'},
  {path: '/learn/:chapterId/:actionId'},
  {path: '/learn/:chapterId/:actionId/r'},
  {path: '/session'},
  {path: '/session/:chapterId'},
  {path: '/group'},
  {path: '/notes'},
  {path: '/workbook'},
  {path: '/workbook/progress'},
];

export const publishCourse = (metadata, roles) => {
  if (!!roles?.includes('independentEditor')) {
    const modal = showModal({
      title: intl.get('home_group_invite'),
      component: <UpsellModal type="free" track="generic_starter" close={() => modal.close()} />,
      onlyContent: true,
      className: 'add-action-modal',
    })
    return
  }

  trackButton({
    button: 'publish_course',
    on_screen: 'edit_session',
  })

  trackButtonEnhanced({
    button: 'Share',
    onScreen: 'Edit Session',
  })

  const drawer = showDrawer({
    footer: { hide: true },
    maxWidth: 'max-w-3xl',
    component: <PublishDialog currentCompanyType={null} refetchQuery={() => null} onClose={() => drawer?.current.close()} />,
    fromRight: true,
  })
}


const CountdownTimer = ({ targetTimestamp  }: { targetTimestamp : number }) => {
  const [timeLeft, setTimeLeft] = useState<{ days?: number; hours?: number, daysBehind?: number, hoursBehind?: number }>({})
  const calculateTimeLeft = () => {
    const now = new Date().getTime()
    const difference = targetTimestamp - now

    if (difference <=0 && difference >= -86400000) {
      const hoursBehind = Math.floor((difference / (1000 * 60 * 60)) % 24)
      setTimeLeft({ hoursBehind })
    } else if (difference <= -86400000) {
      const daysBehind = Math.floor(difference / (1000 * 60 * 60 * 24))
      setTimeLeft({ daysBehind })
    } else if (difference < 86400000) {
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      setTimeLeft({ hours })
    } else {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      setTimeLeft({ days })
    }
  }

useEffect(() => {
  calculateTimeLeft()
}, [targetTimestamp])

const dueDate = targetTimestamp ? new Date(targetTimestamp).toLocaleDateString() : ''

return (
  <div>
    <div className={`flex items-center justify-center h-full rounded-md ml-3`}>
      <CalendarDay className={`mr-2 w-5 h-5 ${(timeLeft.days && timeLeft?.days > 0) || (timeLeft?.hours && timeLeft?.hours > 0) ? 'fill-green-600': 'fill-coral'}`} tooltip={`${targetTimestamp > Date.now() ? `Due date: ${dueDate}` : `Was due on: ${dueDate}`}`} />
      {timeLeft.days && <span className={`${timeLeft.days > 0 ? 'text-green-600': 'text-coral'}`}>
        {timeLeft.days === 1 ? '' : timeLeft.days} {`${timeLeft.days === 1 ? 'Tomorrow': 'days'}`}
      </span>}
      {timeLeft.hours && <span className={`${timeLeft.hours > 0 ? 'text-green-600': 'text-coral'}`}>
        {timeLeft.hours} {`${timeLeft.hours === 1 ? 'hour left': 'hours left'}`}
      </span>}
      {timeLeft.hoursBehind && <span className="text-coral">
        {`${Math.abs(timeLeft.hoursBehind)} hours ago`}
      </span>}
      {timeLeft.daysBehind && <span className="text-coral">
        {`${timeLeft.daysBehind && Math.abs(timeLeft.daysBehind) === 1 ? 'Yesterday' : timeLeft.daysBehind && `${Math.abs(timeLeft.daysBehind)} days ago`}`}
      </span>}
    </div>
  </div>
)
}

const CompletedProgramWithDueDate = () => {
return (
  <div className="flex items-center justify-center ml-3 mx-auto">
    <CheckIcon className="w-5 h-5 fill-green-600 mr-2" /><span className="text-green-600">Completed</span>
  </div>
)

}

export const Learn = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  const { whiteLabelData, setWhiteLableActiveWorkspaceId, isWhiteLabelDataLoading } = useWhiteLabel()
  const accentColor = whiteLabelData.accentColor
  const primaryColor = whiteLabelData.primaryColor

  const customStyle = {
    ...(accentColor ? {'--accent-color': accentColor } as React.CSSProperties : {}),
    ...(primaryColor ? { '--primary-color': primaryColor } as React.CSSProperties : {}),
  }


  const matchingRoutes = matchRoutes(routesPatterns, history.location?.pathname || location.pathname)
  const matchingRoute = routePatterns.find(route => matchPath(route, history.location?.pathname || location.pathname));

  const [_, subroute, route] = (matchingRoute || '').split('/')
  const [menuExpanded, setMenuExpanded] = useState(localStorage.getItem('gnowbe_menu_expanded') === 'false' ? false : true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [chapterListOpen, setChapterListOpen] = useState(false)
  const [tab, changeTab] = useState(route || 'chapter')
  const _isOffline = useRef(false)
  const _offlineTimeout = useRef<any>(null)
  const _initiatedSubs = useRef(false)
  const [showPlaceholderAction, setShowPlaceholderAction] = useState(false)
  const [showPlaceholderChapter, setShowPlaceholderChapter] = useState(false)

  const { data: appData } = useQuery<AppState>(getAppStateQuery)
  const [companyId, courseId] = appData?.appState.currentCompanyIdCourseId.split('-') || ['', '']

  // Current subscription date
  const { data: subData, subscribeToMore: subscribeToMoreCurrent } = useQuery<GetCurrentSubscriptionRes, GetCurrentSubscriptionVars>(getCurrentSubscriptionQuery, {
    skip: !appData,
    variables: { userId: appData!.appState.loggedInAs.uid },
  })

  // Data for notes icon to better display with banner or without
  const { data: metaData } = useQuery<GetUserMetadataRes>(getUserMetadata, {
    variables: { userId: appData?.appState.loggedInAs.uid },
  })

  const { data: bannerData } = useQuery<GetUserNotificationBannersRes>(getUserNotificationBannersQuery)

  const dismissedBannersId = Object.keys(metaData?.user?.metadata?.general?.dismissedBanners ?? {})
  const everyBannerDismissed = bannerData?.banners.every(b => dismissedBannersId.includes(b.bannerId))

  const isEditor = subData?.user.editorCourses.includes(courseId) || false

  // User and active course
  const { data: userData, subscribeToMore: subscribeToMoreSubscription } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, {
    skip: !appData || !companyId || !courseId,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
  })

  // Chapters for active course
  const { data: chapterData, subscribeToMore: subscribeToMoreChapters } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !subData || !companyId || !courseId,
    variables: { companyId, courseId, returnAllAssessments: subData?.user.editorCourses.includes(courseId) || false },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  // Multiple choice status for active course
  const { subscribeToMore: subscribeToMoreMcStats } = useQuery(getMcStatsForCourseQuery, {
    skip: !appData || !companyId || !courseId,
    variables: { companyId, courseId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  // Engagements for active course
  const { data: engagementData, subscribeToMore: subscribeToMoreEngagement } = useQuery<GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars>(getEngagementsForSubscriptionQuery, {
    skip: !appData || !companyId || !courseId,
    variables: { companyId, courseId, userId: appData!.appState.loggedInAs.uid },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  // Study for active course
  const { subscribeToMore: subscribeToMoreStudies } = useQuery(getStudyForSubscriptionQuery, {
    skip: !appData || !companyId || !courseId,
    variables: { companyId, courseId, userId: appData?.appState.loggedInAs.uid },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { data: orgData, loading: orgLoading, refetch } = useQuery<GetOrganizationsRes, GetOrganizationsVars>(getOrganizationsQuery, {
    skip: !appData,
    variables: {
      searchText: '',
      skip: 0,
      limit: 100,
      limitToUserOrganizations: appData?.appState.loggedInAs.uid || '',
      limitToManagerOrganizations: appData?.appState.loggedInAs.uid || '',
      disabled: false,
    },
    errorPolicy: 'all',
  })

  const currentChapterId = getCurrentChapterId({
    courseId,
    chapters: chapterData?.chapters || [],
    chaptersUnlocked: userData?.subscription.course?.chaptersUnlocked || 'unlocked',
    currentDay: userData?.subscription.progress?.currentDay || 0,
    isEditing: appData?.appState.isEditing || false,
    isEditor: subData?.user.editorCourses.includes(courseId) || false,
    matchChapterId: params.chapterId,
    stateChapterId: appData?.appState.activeChapterId || '',
  })
  const [activeWorkspaceId, setActiveWorkspaceId] = useState<string>(getMeta(subData?.user.metadata, 'activeWorkspaceId'))

  const [deleteChapterMut] = useMutation<DeleteChapterRes, DeleteChapterVars>(deleteChapterMutation, { variables: { courseId, chapterId: currentChapterId }})

  useEffect(() => {
    if (subData?.user.metadata) {
      setWhiteLableActiveWorkspaceId(activeWorkspaceId)
    }
  }, [subData?.user.metadata, activeWorkspaceId, setWhiteLableActiveWorkspaceId])

  // If course expired navigate to diffferent course
  // Otherwise set appropriate listeners
  useEffect(() => {
    const distributionParams = userData?.subscription.company?.subsDistributionParams.find(c => c.courseId === userData?.subscription.courseId) || subsDistributionParamsFactory()
    const isExpired = isCourseExpired({
      absoluteDeadline: distributionParams.absoluteDeadline,
      startedAt: userData?.subscription.progress?.startedAt || 0,
      relativeDeadline: distributionParams.relativeDeadline,
    })

    if (isExpired) {
      navigate('/home')
      changeCurrentCourse({ courseId, courseKey: undefined, enableEditing: false })
    }
    else {
      setTimeout(() => {
        positionStickyTools()
      }, 100)

      window.addEventListener('online',  updateOnlineStatus)
      window.addEventListener('offline', updateOnlineStatus)
      window.addEventListener('resize', debounce(positionStickyTools, 50))

      if (['session', 'group', 'notes', 'workbook', 'members'].indexOf(subroute) >= 0) {
        if (subroute === 'session') {
          const id = location.pathname.split('/session/')[1]
          navigate(`/learn/${id}`)
        }
        else if (subroute === 'workbook') {
          const progress = location.pathname.split('/workbook/')[1]
          if (progress) {
            navigate('/learn/progress')
          }
          else {
            navigate('/learn/workbook')
          }
        }
        else {
          navigate(`/learn/${subroute}`)
        }
      }
    }

    if (location.hash) {
      setTimeout(() => {
        const el = document.getElementById(location.hash)
        if (el) el.scrollIntoView({ behavior: 'smooth' })
      }, 250)
    }

    return () => {
      window.removeEventListener('online',  updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
      window.removeEventListener('resize', debounce(positionStickyTools, 50))
      resetSubscriptions()
    }
  }, [])

  // Initiated subscriptions to data once everything loaded
  useEffect(() => {
    if (_initiatedSubs.current) return
    if (!userData?.subscription.company || !subData) return

    const distributionParams = userData?.subscription.company?.subsDistributionParams.find(c => c.courseId === userData?.subscription.courseId) || subsDistributionParamsFactory()
    const isExpired = isCourseExpired({
      absoluteDeadline: distributionParams.absoluteDeadline,
      startedAt: userData?.subscription.progress?.startedAt || 0,
      relativeDeadline: distributionParams.relativeDeadline,
    })

    if (isExpired) {
      navigate('/home')
      changeCurrentCourse({ courseId, courseKey: undefined, enableEditing: false })
      return () => {}
    }

    initiateSubscriptions()
    _initiatedSubs.current = true
  }, [userData, subData])

  // Fire chapter viewed event and update active chapter
  useEffect(() => {
    if (appData && userData?.course.type !== 'scorm') {
      chapterViewed(appData.appState.currentCompanyIdCourseId, currentChapterId)
    }

    setTimeout(() => {
      if (userData?.course.type !== 'scorm' && currentChapterId) {
        updateAppCache('activeChapterId', currentChapterId)
      }
    }, 500)
  }, [currentChapterId])

  // If on action url scroll to main learn element
  useEffect(() => {
    if (route === ':chapterId') {
      const query = location.hash
      if (query) {
        const action = document.getElementById(query)
        if (action) {
          setTimeout(() => {
            const el = document.getElementById('learn-main')
            el?.scrollTo && el.scrollTo(0, action.offsetTop - 160)
          })
        }
      }
    }

    positionStickyTools()
    changeTab(route || 'chapter')
  }, [location])

  // Position sticky tools
  useEffect(() => {
    setTimeout(() => {
      positionStickyTools()
    }, 175)
  }, [menuExpanded])

  const initiateSubscriptions = () => {
    if (!userData?.subscription.company || !subData) {
      throw Error('subscriptions didn\'t initialize')
    }

    const subSub = subscribeToMoreSubscription<UserSubscriptionChangesRes>({
      document: listenUserSubscriptionChangesListener,
      variables: {
        companyId: userData.subscription.companyId,
        courseId: userData.subscription.courseId,
        userId: subData.user.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev

        const data = subscriptionData.data.listenUserSubscriptionChanges
        const progress: UserProgress = data.progress

        if (data?.__typename === 'UserSubscription') {
          return produce(prev, (draft) => {
            if (!progress) return prev

            if (draft.subscription?.progress) {
              draft.subscription.progress = {
                ...draft.subscription.progress,
                ...omit(['__typename'], pickBy(v => v != null, progress)),
              }
            }
            else {
              draft.subscription.progress = userProgressFactory(progress)
            }
          })
        }

        if (data?.__typename === 'ProgressReviewUpdated') {
          const d = data as ProgressReviewUpdated

          let review: ProgressReview = {
            ...prev.subscription.progress?.review,
            ...pickBy(v => v != null, d),
            __typename: 'ProgressReview',
          }

          if (d.removedReviewers && d.removedReviewers.length > 0) {
            review = {
              ...review,
              reviewers: (review.reviewers || []).filter(r => r.id !== d.removedReviewers![0]),
            }
          }

          if (d.addedReviewers) {
            review = {
              ...review,
              reviewers: (review.reviewers || []).concat(d.addedReviewers),
            }
          }

          if (d.updatedReviewers) {
            review = {
              ...review,
              reviewers: (review.reviewers || []).filter(r => r.id === d.updatedReviewers![0].id).concat(d.updatedReviewers),
            }
          }

          const updatedReview = produce(prev, (draft) => {
            if (draft.subscription.progress) {
              draft.subscription.progress.review = review
            }
          })

          return updatedReview
        }

        return prev
      },
    })
    window['subs'] = window['subs'].concat(subSub)

    // subscribe to study changes
    const studySub = subscribeToMoreStudies<StudyForSubscriptionChangesRes>({
      document: listenStudyForSubscriptionChangesSubscription,
      variables: {
        companyId: userData?.subscription.companyId,
        courseId: userData?.subscription.courseId,
        userId: subData.user.id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev) return

        if (!subscriptionData.data) return prev
        const data = subscriptionData.data.listenStudyForSubscriptionChanges

        if (
          data?.__typename === 'StudyViewedUpdated' ||
          data?.__typename === 'StudyAnswerUpdated' ||
          data?.__typename === 'StudyCompletionUpdated'
        ) {
          const d = data as StudyViewedUpdated|StudyAnswerUpdated|StudyCompletionUpdated
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          const studies = prev.study?.filter(s => !(s?.actionId === d.actionId && s?.chapterId === d.chapterId)) || []
          s = s
            ? {...s, ...d, __typename: s.__typename}
            : userStudyFactory(d)
          const r = {
            ...prev,
            study: studies.concat(s),
          }
          return r
        }

        if (data?.__typename === 'StudyTimestampsUpdated') {
          const d = data as StudyTimestampsUpdated
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          s = s
            ? {...s, timestamps: {...s.timestamps, ...pickBy(v => v != null, d.timestamps)}}
            : userStudyFactory(d as any)

          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyShared') {
          const d = data as StudyShared
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {...s, sharedAt: Number(new Date())}
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyUnshared') {
          const d = data as StudyShared
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {...s, sharedAt: null}
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyShareLiked') {
          const d = data as StudyShareLiked
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {...s, likesCount: s.likesCount + 1, likedByMe: d.userIdLiked === subData.user.id}
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyShareUnliked') {
          const d = data as StudyShareUnliked
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {...s, likesCount: Math.max(0, s.likesCount - 1), likedByMe: d.userIdLiked === subData.user.id ? false : s.likedByMe}
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyShareCommented') {
          const d = data as StudyShareCommented
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {
            ...s,
            commentsCount: s.commentsCount + 1,
            lastCommentAt: d.messageAt,
          }
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyShareCommentRemoved') {
          const d = data as StudyShareCommentRemoved
          let s: UserStudy&Typename|undefined = prev.study?.find(s => s.actionId === d.actionId && s.chapterId === d.chapterId) || undefined
          if (!s) return prev
          s = {
            ...s,
            commentsCount: Math.max(0, s.commentsCount - 1),
          }
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            study: studies.concat(s),
          }
        }

        if (data?.__typename === 'StudyRemoved') {
          const d = data as StudyRemoved
          const studies = prev.study?.filter(s => !(s.actionId === d.actionId && s.chapterId === d.chapterId)) || []
          return {
            ...prev,
            studies,
          }
        }

        if (data?.__typename === 'StudyReviewUpdated') {
          const d = data as StudyReviewUpdated
          const oldStudies = prev.study?.filter(s => !(s.chapterId === d.chapterId && s.actionId === d.actionId)) || []
          const study: UserStudy = prev.study?.find(s => s.chapterId === d.chapterId && s.actionId === d.actionId) || undefined
          let newStudy = study

          if (study.review) {
            if (d.removedReviews && d.removedReviews.length > 0) {
              newStudy = {
                ...study,
                review: {
                  ...study.review,
                  assessments: study?.review?.assessments?.filter(a => a.id !== d.removedReviews![0]) || [],
                },
              }
            }

            if (d.updatedReviews && d.updatedReviews.length > 0) {
              newStudy = {
                ...study,
                review: {
                  ...study.review,
                  assessments: (study?.review?.assessments?.filter(a => a.id !== d.updatedReviews![0].id) || []).concat(d.updatedReviews[0]),
                },
              }
            }
          }

          if (d.addedReviews && d.addedReviews.length > 0) {
            newStudy = {
              ...study,
              review: study.review
                ? {
                  ...study.review,
                  assessments: (study?.review?.assessments || []).concat(d.addedReviews),
                }
                : studyReviewFactory({
                  companyId: d.companyId,
                  courseId: data.courseId,
                  chapterId: data.chapterId,
                  actionId: d.actionId,
                  timestamps: study.timestamps,
                }),
            }
          }

          return {
            ...prev,
            study: oldStudies.concat(newStudy),
          }
        }

        if (data?.__typename === 'StudyReviewTimestampsUpdated') {
          const d = data as StudyTimestampsUpdated
          const oldStudies = prev.study.filter(s => !(s.chapterId === d.chapterId && s.actionId === d.actionId))
          const study: UserStudy = prev.study.find(s => s.chapterId === d.chapterId && s.actionId === d.actionId)
          const newStudy = {
            ...study,
            review: {
              ...study.review,
              timestamps: {
                ...d.timestamps,
                __typename: 'Timestamps',
              },
            },
          }
          return {
            ...prev,
            study: oldStudies.concat(newStudy),
          }
        }

      },
    })
    window['subs'] = window['subs'].concat(studySub)

    // subscribe to MC stats changes
    const statsSub = subscribeToMoreMcStats({
      document: listenMcStatsForCourseChangesSubscription,
      variables: {
        companyId: userData?.subscription.companyId,
        courseId: userData?.subscription.courseId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev

        const data = subscriptionData.data.stats
        const oldStats = prev.study && prev.study.filter(s => !(s.actionId === data.actionId && s.chapterId === data.chapterId)) || []
        const newStats = oldStats.concat([data])
        return {
          ...prev,
          stats: newStats,
        }
      },
    })
    window['subs'] = window['subs'].concat(statsSub)

    // subscribe to engagement changes
    const engagementSub = subscribeToMoreEngagement<any, any>({
      document: listenEngagementsForSubscriptionChangesSubscription,
      variables: {
        companyId: userData?.subscription.companyId,
        courseId: userData?.subscription.courseId,
        userId: subData.user.id,
        version: 2,
      },
      updateQuery: (prev, { subscriptionData }): any => {
        if (!subscriptionData.data) return prev
        const data: ListenEngagementsForSubscriptionChangesRes = subscriptionData.data.listenEngagementsForSubscriptionChanges

        if (data?.__typename === 'EngagementTimestampsUpdated' && data?.timestamps) {
          return produce(prev, (draft) => {
            if (draft) {
              const index = draft.engagement?.findIndex(e => e.id === data.id) || -1
              if (index !== -1) {
                if (draft.engagement[index].timestamps) {
                  draft.engagement[index].timestamps = {
                    ...draft.engagement[index].timestamps,
                    updatedAt: data.timestamps.updatedAt || draft.engagement[index].timestamps.updatedAt || 0,
                    updatedAtTree: data.timestamps.updatedAtTree || draft.engagement[index].timestamps.updatedAtTree || 0,
                  }
                }
                // else {
                //   draft.engagement.concat({
                //     id: data.id,
                //     timestamps = timestampsFactory({
                //       updatedAt: data.timestamps.updatedAt || draft.engagement[index].timestamps.updatedAt || 0,
                //       updatedAtTree: data.timestamps.updatedAtTree || draft.engagement[index].timestamps.updatedAtTree || 0,
                //     })
                //   })
                // }
              }
            }
          })
        }

        return produce(prev, (draft) => {
          if (draft) {
            const index = draft.engagement?.findIndex(e => e.id === data.id)
            if (index !== -1) {
              Object.keys(data).forEach((k) => {
                if (data[k] == null || k === 'timestamps' || k === '__typename') return
                draft.engagement[index][k] = data[k]
              })
            }
          }
        })
      },
    })
    window['subs'] = window['subs'].concat(engagementSub)

    // subscribe to chapter changes
    const chaptersSub = subscribeToMoreChapters<ListenChaptersForCourseChangesRes>({
      document: listenChaptersForCourseChangesListener,
      variables: {
        companyId: userData?.subscription.companyId || '',
        courseId: userData?.subscription.courseId || '',
        returnAllAssessments: isEditor,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const data = subscriptionData.data.listenChaptersForCourseChanges

        if (data.courseId && data.courseId !== userData?.subscription.courseId || data.chapter && data.chapter.courseId !== userData?.subscription.courseId) return prev

        if (data?.__typename === 'ChapterCreated') {
          slog.i('Session created.')

          const uc = produce(prev, (draft) => {
            const ci = draft?.chapters && draft.chapters.findIndex(c => c.id === data.chapter.id)
            if (ci !== -1) {
              draft.chapters[ci] = data.chapter
            }
            else {
              draft.chapters.push(data.chapter)
            }
          })

          if (data.chapter.id === currentChapterId) {
            setTimeout(() => {
              navigate(`/learn/${data.chapter.id}`)
            }, 0)
          }

          if (appData?.appState.isImportingPpt) {
            updateAppCache('isImportingPpt', false)
          }

          return uc
        }

        if (data?.__typename === 'ChapterOrderUpdated') {
          slog.i('Session reordered.')
          const uc = produce(prev, (draft) => {
            const ci = draft.chapters.findIndex(c => c.id === data.id)
            if (ci === -1) return

            draft.chapters[ci].order = data.order
          })

          return uc
        }

        // if (data?.__typename === 'ChapterUpdated') {
        //   const uc = produce(prev, (draft) => {
        //     const ci = draft.chapters.find(c => c.id === data.chapter.id)
        //     if (ci === -1) return

        //     draft.chapters[ci] = data.chapter
        //   })

        //   return uc
        // }

        if (data?.__typename === 'ChapterRemoved') {
          slog.i('Session removed.')
          return produce(prev, (draft) => {
            draft.chapters = draft.chapters.filter(c => c.id !== data.id)
          })
        }

        if (data?.__typename === 'ActionOrderUpdated') {
          slog.i('Action reordered.')
          const uc = produce(prev, (draft) => {
            const ci = draft.chapters.findIndex(c => c.id === data.chapterId)
            if (ci === -1) return
            const ai = draft.chapters[ci].actions.findIndex(a => a.id === data.id)
            if (ai === -1) return

            draft.chapters[ci].actions[ai].order = data.order
          })

          return uc
        }

        return prev
      },
    })
    window['subs'] = window['subs'].concat(chaptersSub)
  }

  const resetSubscriptions = () => {
    window['subs'].forEach((unsub) => {
      unsub()
    })
    window['subs'] = []
    _initiatedSubs.current = false
  }

  const deleteChapter = (e?) => {
    e && e.stopPropagation()

    showModal({
      title: intl.get('chapter_tooltip_remove'),
      component: (
        <>
          <div>{intl.get('chapter_confirm_remove_text', { 0: currentChapter?.order, 1: currentChapter?.title })}</div>
          <div className="mt-4 text-red-600 font-bold">{intl.get('chapter_remove_input_popup_message', { 0: intl.get('delete').toUpperCase() })}</div>
        </>
      ),
      destructive: true,
      primaryText: intl.get('global_delete'),
      prompt: true,
      primaryAction: (r) => {
        if (!r || r !== 'DELETE') return toast.error('Please type DELETE (uppercase) to delete the session.')

        deleteChapterMut({
          variables: {
            courseId,
            chapterId: currentChapterId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            deleteChapter: {
              courseId,
              id: currentChapterId,
              __typename: 'ChapterId',
            },
          },
          update: (proxy, { data }) => {
            const app: any = proxy.readQuery({ query: getAppStateQuery })
            const user: any = proxy.readQuery({
              query: getCurrentSubscriptionQuery,
              variables: {
                userId: app.appState.loggedInAs.uid,
              },
            })
            const cc = user.user.profile.currentSubscription
            const [companyId, courseId] = cc && cc.split('-') || ['', '']
            const d2: any = proxy.readQuery({
              query: getChaptersForCourseQuery,
              variables: {
                companyId,
                courseId,
                returnAllAssessments: true,
              },
            })
            if (!d2) return

            const chapter = d2.chapters.find(c => c.id === currentChapterId)
            if (!chapter) return

            proxy.writeQuery({
              query: getChaptersForCourseQuery,
              variables: {
                companyId,
                courseId,
                returnAllAssessments: true,
              },
              data: produce(d2, (draft) => {
                draft.chapters = draft.chapters.filter(c => c.id !== currentChapterId)
              }),
            })
          },
        })
      },
    })
  }

  const updateOnlineStatus = async () => {
    const online = await isOnline()
    if (online && _isOffline.current) {
      _offlineTimeout.current = clearTimeout(_offlineTimeout.current)
      _offlineTimeout.current = setTimeout(() => {
        if (!online) return

        _isOffline.current = false
        resetSubscriptions()
        initiateSubscriptions()
      }, 500)
    }

    if (!online) {
      _isOffline.current = true
    }
  }

  const positionStickyTools = () => {
    const stickyTools = document.getElementById('sticky-tools')
    const stickContainer = document.querySelector('.stick-container')
    if (!stickyTools || !stickContainer) return
    const stickContainerRect = stickContainer.getBoundingClientRect()
    stickyTools.style.left = `${stickContainerRect.width + 24}px`
    stickyTools.style.top = `${stickContainerRect.top / 2}px`
  }

  if (!appData || !userData || !chapterData || !engagementData || !subData) return <FullscreenLoader />

  stickybits('.sticky-nav')

  const { subscription, subscription: { company }, course } = userData
  const isAgent = isAgente(subData.user, company?.organizationId, companyId)
  const isEditing = appData.appState.isEditing
  const canInvite = (company?.peerInvitationMode === 'approval' || company?.peerInvitationMode === 'open' || isAgent)
  const distributionParams = company?.subsDistributionParams.find(c => c.courseId === userData?.subscription.courseId) || subsDistributionParamsFactory()
  const isExpired = isCourseExpired({ absoluteDeadline: distributionParams.absoluteDeadline, startedAt: subscription.progress?.startedAt || 0, relativeDeadline: distributionParams.relativeDeadline })
  const currentChapter = chapterData.chapters.find(c => c.id === currentChapterId)
  const lastChapter = chapterData.chapters.slice().sort((a, b) => b.order - a.order)[0]
  const allActions = chapterData.chapters.reduce((a: ActionType[], c) => {
    return a.concat(c.actions)
  }, [])
  const certificate = allActions.find(a => a.contentType === 'certificate')
  const courseDueDate = company?.subsDistributionParams?.find(distrParams => distrParams.courseId === courseId)?.dueDate
  const dueDate = courseDueDate ? new Date(courseDueDate).toLocaleDateString() : ''

  const showNewActionModal = () => {
    const modal = showModal({
      title: intl.get('action_modal_select_type'),
      component: (
        <NewActionModal
          chapter={chapterData.chapters.find(c => c.id === currentChapterId)}
          manualReviews={course.manualReviews}
          certificate={certificate && `${certificate.chapterId}/${certificate.id}` || ''}
          features={subData.user.features}
          reopen={() => {
            setTimeout(() => {
              showNewActionModal()
            }, 0)
          }}
          close={() => modal.close()}
          whiteLabelData={whiteLabelData} />
      ),
      className: 'add-action-modal',
      hideFooter: true,
      scrollable: true,
    })
  }

  const signedUserImage = getSignedValue(subData?.user.profile.imageUrl || '')
  const orgHasCustomLogoAndIsEnterprise = orgData?.res.organizations.filter(org => org.customPalette?.dashboardLogo && (org.billingService?.planName?.includes('Enterprise') || org.product === 'UL')).some(o => o.id === activeWorkspaceId)
  const logo = whiteLabelData.largeLogoUrl || "/images/gnowbe.svg"
  const rtlDir: React.CSSProperties = isRtlLanguage(course.translatedToLanguage)  ? { direction: 'rtl', textAlign: 'right' } : {}
  const magicSessionCreationEnabled = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('generateSessionAi')
  const magicActionCreationEnabled = orgData?.res.organizations.find(o => o.id === activeWorkspaceId)?.featureFlags.includes('generateActionAi')

  return (
    <AssessmentsSupervisor
      companyId={subscription.companyId}
      course={userData.course}
      chapters={chapterData.chapters}
      userId={subData.user.id}
      isEditor={isEditor}
      isEditing={appData.appState.isEditing}
      progress={subscription.progress}
      engagements={engagementData.engagement}>
      <div className="h-screen flex print:overflow-visible overflow-hidden font-noto-sans" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
        {/* mobile menu */}
        <div className="md:hidden">
          <Transition show={menuOpen}>
            <div className="fixed inset-0 flex z-40">
              <TransitionChild
                as="div"
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="fixed inset-0" onClick={() => setMenuOpen(false)}>
                  <div className="absolute inset-0 bg-gray-600 opacity-75" />
                </div>

                <TransitionChild
                  as="div"
                  enter="transition ease-in-out duration-300"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full">
                  <div className="relative flex-1 flex flex-col w-72 bg-white h-screen">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setMenuOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>

                    <div className="px-6 py-4" style={{ backgroundColor: whiteLabelData.bgColor || '#f9f7f2'}}>
                      <div className="bg-lightwarmgray rounded-full w-24 h-24 mx-auto">
                        {subData?.user.profile.imageUrl && <img src={signedUserImage} className="w-full h-full rounded-full" />}
                      </div>

                      <div className="mt-4 mb-6 text-center">
                        <div className="">{subData?.user.profile.fullName}</div>
                        <div className="text-coral font-medium">{subData?.user.email}</div>
                      </div>

                      <div className="flex justify-between">
                        <MiniStat
                          text={intl.get('minutes', { minutes: (subData?.user.secondsSpent || 0) / 60 })}
                          number={(subData?.user.secondsSpent || 0) / 60}
                        />

                        <MiniStat
                          text={intl.get('sessions', { sessions: subData?.user.completedChaptersCount })}
                          number={subData?.user.completedChaptersCount}
                        />

                        <MiniStat
                          text={intl.get('actions_label')}
                          number={subData?.user.completedChaptersCount}
                        />
                      </div>
                    </div>

                    {isEditor && (
                      <div className="p-4 pb-0">
                        <GnowbefyButton
                          navbar={true}
                          isEditing={!!appData?.appState.isEditing}
                          courseId={courseId}
                          currentSubscription={appData?.appState.currentCompanyIdCourseId || ''}
                          urlCourseKey={null}
                          email={subData.user.email}
                          isPublisher={subData.user.roles.includes('publisher')}
                          isIndependentEditor={subData.user.roles.includes('independentEditor')}
                          fullWidth={true}
                          onPublish={() => publishCourse(subData.user.metadata, subData.user.roles)}
                          subData={subData}
                          companyId={companyId}
                          metadata={subData.user.metadata}
                        />
                      </div>
                    )}

                    <div className="flex-1 h-0 pb-4 overflow-y-auto">
                      <SidebarMenu
                        tab={tab}
                        menuExpanded={menuExpanded}
                        mobile={true}
                        isEditing={isEditing}
                        workbookDisabled={course.workbookDisabled}
                      />
                    </div>
                  </div>

                  <div className="shrink-0 w-14" />
                </TransitionChild>
              </TransitionChild>
            </div>
          </Transition>
        </div>

        {/* mobile chapter list */}
        <div className="md:hidden">
          <Transition show={chapterListOpen}>
            <div className="fixed inset-0 flex z-40">
              <TransitionChild
                as="div"
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0" onClick={() => setChapterListOpen(false)}>
                  <div className="absolute inset-0 bg-gray-600 opacity-75" />
                </div>

                <TransitionChild
                  as="div"
                  enter="transition ease-in-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <div className="relative flex-1 flex flex-col bg-white h-screen" style={{ marginLeft: '16.7%' }}>
                    <div className="absolute top-0 left-0 -ml-12 pt-2">
                      <button
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setChapterListOpen(false)}>
                        <span className="sr-only">Close session list</span>
                        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>

                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <div className="sm:px-4">
                        <div className="px-4 sm:px-0 pb-4 mb-4 border-b border-gray-200">
                          <MiniCourseDetails
                            isEditing={isEditing}
                            companyId={userData.subscription.companyId || userData.course.testCompany?.id || ''}
                            companyName={userData.subscription.company?.groupName || userData.subscription.company?.name || 'Unknown Group'}
                            courseId={courseId}
                            courseTitle={userData.course.title}
                            completion={userData.subscription.progress?.completion || 0}
                            canInvite={canInvite && !isExpired}
                            isEditor={isEditor}
                            isAgent={isAgent}
                            isSandbox={userData.subscription.company?.companyType === 'test' || false}
                            courseType={userData?.course.chaptersUnlocked}
                            rtlDir={rtlDir}
                          />
                        </div>

                        <ChapterList
                          mobile={true}
                          isEditor={isEditor}
                          chapterId={currentChapterId}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="shrink-0 w-14" />
                </TransitionChild>
              </TransitionChild>
            </div>
          </Transition>
        </div>

        {/* desktop sidebar */}
        <div className="hidden print:hidden md:flex md:shrink-0">
          <div className={`flex flex-col transition-all overflow-hidden ${menuExpanded ? 'w-80' : 'w-16'}`}>
            <div className={`flex flex-col h-0 flex-1 border-r border-gray-200 bg-white ${menuExpanded ? 'w-80' : 'w-16'}`}>
              <div className="flex-1 flex flex-col pt-5 overflow-y-auto overflow-x-hidden">
                <div className="flex">
                {!orgHasCustomLogoAndIsEnterprise && !isWhiteLabelDataLoading &&
                    <button onClick={() => navigate('/home')} className={`h-7 ${!menuExpanded ? 'hidden' : 'ml-6'}`} aria-label="Return Home">
                      <img src={logo} className="h-7 rounded-sm" alt="Gnowbe Logo" />
                    </button>}
                  {(orgHasCustomLogoAndIsEnterprise && !isWhiteLabelDataLoading && whiteLabelData.largeLogoUrl) &&
                    <button onClick={() => navigate('/home')} className={`h-7 ${!menuExpanded ? 'hidden' : 'ml-6'}`} aria-label="Return Home">
                      <img src={whiteLabelData.largeLogoUrl} className="h-7 rounded-sm" alt="Gnowbe Logo" />
                    </button>
                  }

                  <div className={`flex flex-1 items-center shrink-0 px-5 ${menuExpanded ? 'justify-end' : 'justify-center'}`}>
                    <Tippy content={`${menuExpanded ? 'Collapse' : 'Expand'}`} placement="right">
                      <button aria-label={`${menuExpanded ? 'Collapse' : 'Expand'} Sidebar`} className={`icon ${menuExpanded ? 'icon-collapse' : 'icon-bars-light font-bold'} w-6 text-xl hover:text-coral`} onClick={() => {
                        setMenuExpanded(!menuExpanded)
                        localStorage.setItem('gnowbe_menu_expanded', String(!menuExpanded))
                      }} />
                    </Tippy>
                  </div>
                </div>

                {isEditing
                  ? <div className={`p-4 ${menuExpanded ? 'border-b border-gray-300' : undefined}`}>
                      {menuExpanded && (
                        <>
                          <div className="mt-4">
                            <div className="text-lg font-medium flex items-start">
                              <div className="flex-1">
                                {intl.get('edit_mode')}

                                <div className="text-sm mt-1">{intl.get('welcome_to_edit_mode')}</div>
                              </div>
                            </div>

                            <div className="mt-4">
                              <Button
                                text={intl.get('global_program_settings')}
                                className={`w-full border-2 text-base ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
                                style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
                                icon="icon-cog-light"
                                onClick={() => navigate(`/home/publish`)}
                              />
                              <Button
                                text={intl.get('program_share_sandbox')}
                                className={`w-full border-2 text-base mt-4 ${whiteLabelData.accentColor && 'border-[var(--accent-color)] ring-[var(--accent-color)] text-[var(--accent-color)]'}`}
                                style={whiteLabelData.accentColor ? { '--accent-color': whiteLabelData.accentColor } : {}}
                                icon="icon-share-light -mt-0.5"
                                onClick={() => {
                                  navigate('/home/publish#share')
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  : <SidebarMenu
                      isEditing={isEditing}
                      tab={tab}
                      menuExpanded={menuExpanded}
                      mobile={false}
                      workbookDisabled={course.workbookDisabled}
                    />
                }

                {menuExpanded && (
                  <>
                    <div className="p-4 border-l-4 border-transparent">
                      <MiniCourseDetails
                        isEditing={isEditing}
                        companyId={userData.subscription.companyId || userData.course.testCompany?.id || ''}
                        companyName={userData.subscription.company?.groupName || userData.subscription.company?.name || 'Unknown Group'}
                        courseId={courseId}
                        courseTitle={userData.course.title}
                        completion={userData.subscription.progress?.completion || 0}
                        canInvite={canInvite && !isExpired}
                        isEditor={isEditor}
                        isAgent={isAgent}
                        isSandbox={userData.subscription.company?.companyType === 'test' || false}
                        courseType={userData?.course.chaptersUnlocked}
                        rtlDir={rtlDir}
                      />
                    </div>

                    <div className="chapter-list-container-new hidden md:block flex-1 overflow-y-auto overflow-x-hidden border-t border-gray-300">
                      <ChapterList
                        isEditor={isEditor}
                        chapterId={currentChapterId}
                        showPlaceholderAction={showPlaceholderAction}
                        showPlaceholderChapter={showPlaceholderChapter}
                      />
                    </div>

                    {isEditor && isEditing && course.type !== 'scorm' && (
                      <div className="border-t border-gray-200 p-2">
                        <CreateNewChapterButton
                          courseId={courseId}
                          order={userData.course.chaptersCount + 1}
                          lastChapterDisabledUntilDay={lastChapter?.disabledUntilDay}
                          canUploadPpt={subData.user.features.includes('course-from_ppt')}
                          setShowPlaceholderChapter={setShowPlaceholderChapter}
                          disabled={!!userData.course.creatingAIContent}
                          magicSessionCreationEnabled={magicSessionCreationEnabled}
                        />
                      </div>
                    )}
                  </>
                )}

                {!menuExpanded && isEditor && (
                  <div className="flex items-start justify-center flex-1">
                    <div className="flex flex-col space-y-2 mb-4">
                      {isEditing
                        ? <>
                            <Tippy placement="right" content={intl.get('edit_program_details')}>
                              <button className="icon icon-library-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral" onClick={() => {
                                trackButtonEnhanced({
                                  button: 'Edit Program Details',
                                  onScreen: 'Edit Session',
                                })
                                navigate(`/details/${courseId}`)}} />
                            </Tippy>

                            <Tippy placement="right" content={intl.get('share_text')}>
                              <button className="icon icon-upload-light font-bold text-lg w-12 h-12 text-deepgray hover:text-coral" onClick={() => {
                                trackButton({
                                  button: 'publish_course',
                                  on_screen: 'edit_session',
                                })

                                trackButtonEnhanced({
                                  button: 'Share',
                                  onScreen: 'Edit Session',
                                })

                                navigate('/home/publish#share')
                              }} />
                            </Tippy>
                          </>
                        : <div className="relative">
                            <MoreButton
                              sidebar={true}
                              companyId={userData?.subscription.companyId || userData?.course.testCompany?.id || ''}
                              courseId={courseId}
                              courseTitle={userData.subscription.course?.title || 'Program'}
                              canInvite={canInvite && !isExpired}
                              isEditor={isEditor}
                              isAgent={isAgent}
                              groupName={userData.subscription.company?.groupName || userData.subscription.company?.name || 'Unknown group'}
                              isSandbox={userData.subscription.company?.companyType === 'test' || true}
                            />
                          </div>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          id="saving-pill"
          style={{ color: isColorDark(primaryColor) ? '#fafafa' : undefined }}
          className={`absolute top-0 left-1/3 lg:left-20 ml-0 ${menuExpanded ? 'lg:ml-96 -translate-x-1/2' : 'lg:ml-20'} mt-1 lg:mt-5 ${primaryColor ? 'bg-[var(--primary-color)]' : 'bg-lake'} px-4 py-2 rounded-full z-50 opacity-0 font-medium transition-opacity`}
        >
          {intl.get('menu_tooltip_saving')}
        </div>

        <div className="flex flex-col w-0 flex-1 print:overflow-visible overflow-hidden">
          <aside className={`${params.actionId ? 'hidden' : ''} bg-white border-b border-gray-200 md:hidden flex justify-between`}>
            <div className="flex flex-row">
              <button className="h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-actions-multiple_choice" onClick={() => setMenuOpen(true)}>
                <span className="sr-only">Open session list</span>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>

            {courseDueDate ? (
              (userData.subscription.progress?.completion || 0) <= (userData.course.graduationGrade || 80) ? (
                <div className="flex items-center justify-center h-full">
                  <CalendarDay
                    className={`md:hidden h-6 w-6S ${courseDueDate > Date.now() ? 'fill-green-600' : 'fill-coral'}`}
                    tooltip={`${courseDueDate > Date.now() ? `Due date: ${dueDate}` : `Was due on: ${dueDate}`}`}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <CompletedProgramWithDueDate />
                </div>
              )
              ) : null
            }

            <div className="flex items-center relative">
              <MobileHorizontalMenu
                openChapterList={() => setChapterListOpen(true)}
              />
            </div>
          </aside>

          {/* main */}
          <main id="learn-main" className="flex-1 relative print:overflow-visible overflow-y-auto focus:outline-none md:pb-20" tabIndex={0} style={customStyle}>
            <CheckUpdate />

            <div data-test="top-hud" className="hidden print:hidden md:block py-4 sticky-nav bg-white border-b border-gray-200 z-20">
              <Banner />
              <div className="flex justify-between pl-3">
                <BackButton tab={tab} />
                <div className="px-4 flex justify-end">
                  <div className="flex justify-end items-center space-x-5">
                    {isEditor && (
                      <GnowbefyButton
                        navbar={true}
                        yellow={true}
                        isEditing={!!appData?.appState.isEditing}
                        courseId={courseId}
                        currentSubscription={appData?.appState.currentCompanyIdCourseId || ''}
                        urlCourseKey={null}
                        email={subData.user.email}
                        isPublisher={subData.user.roles.includes('publisher')}
                        isIndependentEditor={subData.user.roles.includes('independentEditor')}
                        onPublish={() => publishCourse(subData.user.metadata, subData.user.roles)}
                        subData={subData}
                        companyId={companyId}
                        metadata={subData.user.metadata}
                      />
                    )}

                    {courseDueDate ? (
                      (userData.subscription.progress?.completion || 0) <= (userData.course.graduationGrade || 80) ? (
                        <CountdownTimer targetTimestamp={courseDueDate} />
                      ) : (
                        <CompletedProgramWithDueDate />
                      )
                    ) : null}

                    <HorizontalMenu
                      companyId={companyId}
                      courseId={courseId}
                      showTour={false}
                      showPlanButtons={subData.user.billingManagerOrganizations.length > 0}
                      workspacesTour={() => null}
                      openTour={() => null}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              id="chapter-content"
              className={`print:max-w-full ${tab !== 'publish' ? 'max-w-xl lg:max-w-2xl xl:max-w-3xl' : 'mx-6'} mx-auto mt-0 md:mt-4 relative text-deepgray`} >
              <Suspense fallback={null}>
                {['chapter', ':chapterId'].includes(tab) && (
                  !params.actionId
                    ? <Chapter
                        appData={appData}
                        userData={userData}
                        subData={subData}
                        currentChapterId={currentChapterId}
                        chaptersCount={chapterData.chapters.length}
                        rtlDir={rtlDir}
                        showPlaceholderAction={showPlaceholderAction}
                        setShowPlaceholderAction={setShowPlaceholderAction}
                        magicActionCreationEnabled={magicActionCreationEnabled}
                      />
                    : <Action />
                )}

                {tab === 'group' && <Group />}
                {tab === 'workbook' && <Workbook />}
                {tab === 'progress' && <Overview />}
                {tab === 'resources' && <Goodiebag />}
                {tab === 'notes' && <Notes />}
                {tab === 'members' && <Members />}
                {tab === 'share' && <Share />}
                {tab === 'likes' && <PostLikes />}
                {tab === 'shares' && <Group />}
                {tab === 'feedback' && <Feedback />}

                <div ref={ref => positionStickyTools()} id="sticky-tools" className={`print:hidden hidden absolute top-0 ${tab !== 'publish' ? 'lg:flex flex-col' : undefined} space-y-4`}>
                  {isEditing
                    ? <>
                        {/* <SideIcon id="course-details-tip" text={intl.get('edit_program_details')} icon="icon-library-light" onClick={() => {
                          trackButton({
                            button: 'edit_course',
                            on_screen: 'edit_session',
                          })
                          navigate(`/details/${companyId}-${courseId}`)
                        }} /> */}

                        {/* <SideIcon id="curator-chat-tip" text={intl.get('curator_chat')} icon="icon-comment-alt-light" onClick={() => alert('todo')} /> */}
                        {['chapter', ':chapterId'].includes(tab)
                          ? <SideIcon id="resources-tip" text={intl.get('menu_resources')} icon="icon-shopping-bag-light" onClick={() => navigate('/learn/resources')} />
                          : <SideIcon id="session-tip" text={intl.get('global_chapter')} icon="icon-session-light" onClick={() => {
                            trackButton({
                              button: 'edit_chapter_icon',
                              on_screen: 'resources',
                            })

                            trackButtonEnhanced({
                              button: 'Edit Session',
                              onScreen: 'Resources',
                            })

                            navigate('/learn')
                          }} />
                        }

                        {/* {(currentChapter?.order || 0) > 1 && <SideIcon id="delete-chapter-tip" text={intl.get('chapter_tooltip_remove')} icon="icon-trash-alt-light" onClick={deleteChapter} />} */}
                      </>
                    : tab === 'workbook'
                      ? <SideIcon id="download-pdf-tip" text={intl.get('print_or_save')} icon="icon-arrow-to-bottom-light" onClick={() => window.print()} />
                      : <>
                          <SideIcon id="notes-tip" text={intl.get('notes')} icon="icon-note-light" onClick={() => navigate('/learn/notes')} />
                          {(isEditor || String(subscription.company?.companyType) === 'test') && (
                            <ResetStudy side={true} companyId={companyId} courseId={courseId} userId={subData.user.id} email={subData.user.email} />
                          )}
                        </>
                  }
                </div>
              </Suspense>
            </div>
          </main>
        </div>

        {isEditing && course.type !== 'scorm' && (
          <CmdK
            commands={[
              {
                id: 'new-chapter',
                title: intl.get('create_new_session'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 h-4 mr-3"><path fill="#666" d="M16 72C16 58.75 26.75 48 40 48H88C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72zM80 112V80H48V112H80zM496 80C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H176C167.2 112 160 104.8 160 96C160 87.16 167.2 80 176 80H496zM496 240C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H176C167.2 272 160 264.8 160 256C160 247.2 167.2 240 176 240H496zM496 400C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H176C167.2 432 160 424.8 160 416C160 407.2 167.2 400 176 400H496zM88 208C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232C16 218.7 26.75 208 40 208H88zM48 240V272H80V240H48zM16 392C16 378.7 26.75 368 40 368H88C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392zM80 432V400H48V432H80z"/></svg>,
                onClick: () => createChapter(courseId, chapterData.chapters.length + 1, lastChapter?.disabledUntilDay),
              },
              {
                id: 'new-action',
                title: intl.get('add_new_action'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 h-4 mr-3"><path fill="#666" d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z"/></svg>,
                onClick: showNewActionModal,
              },
              {
                id: 'delete-chapter',
                title: intl.get('chapter_tooltip_remove'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="w-4 h-4 mr-3"><path fill="#666" d="M144 400C144 408.8 136.8 416 128 416C119.2 416 112 408.8 112 400V176C112 167.2 119.2 160 128 160C136.8 160 144 167.2 144 176V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V176C208 167.2 215.2 160 224 160C232.8 160 240 167.2 240 176V400zM336 400C336 408.8 328.8 416 320 416C311.2 416 304 408.8 304 400V176C304 167.2 311.2 160 320 160C328.8 160 336 167.2 336 176V400zM310.1 22.56L336.9 64H432C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56V22.56zM148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7C172.2 32 167.1 34.84 164.2 39.52L148.9 64zM64 432C64 458.5 85.49 480 112 480H336C362.5 480 384 458.5 384 432V96H64V432z"/></svg>,
                onClick: deleteChapter,
              },
              {
                id: 'copy-chapter',
                title: intl.get('copy_session'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-3"><path fill="#666" d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z"/></svg>,
                onClick: () => {
                  localStorage.setItem('copiedChapter', JSON.stringify({ fromCourseId: courseId, fromChapterId: currentChapterId, chapterTitle: currentChapter?.title }))
                  showAlert('Session copied!')
                },
              },
              {
                id: 'view-course-details',
                title: intl.get('program_menu_details'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-3"><path fill="#666" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM256 184c13.25 0 24-10.74 24-24c0-13.25-10.75-24-24-24S232 146.7 232 160C232 173.3 242.7 184 256 184zM304 352h-32V240C272 231.2 264.8 224 256 224H224C215.2 224 208 231.2 208 240S215.2 256 224 256h16v96h-32C199.2 352 192 359.2 192 368C192 376.8 199.2 384 208 384h96c8.836 0 16-7.164 16-16C320 359.2 312.8 352 304 352z"/></svg>,
                onClick: () => navigate(`/details/${courseId}`),
              },
              {
                id: 'publish-course',
                title: intl.get('program_publish'),
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-3"><path fill="#666" d="M212.7 4.688l-144 144c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0L208 54.63V336c0 8.844 7.156 16 16 16s16-7.156 16-16V54.63l116.7 116.7c6.25 6.25 16.38 6.25 22.62 0s6.25-16.38 0-22.62l-144-144C232.2 1.562 228.1 0 224 0S215.8 1.562 212.7 4.688zM416 336v96c0 26.47-21.53 48-48 48h-288C53.53 480 32 458.5 32 432v-96C32 327.2 24.84 320 16 320S0 327.2 0 336v96C0 476.1 35.88 512 80 512h288c44.13 0 80-35.88 80-80v-96c0-8.844-7.156-16-16-16S416 327.2 416 336z"/></svg>,
                onClick: () => publishCourse(subData.user.metadata, subData.user.roles),
              },
              {
                id: 'add-resources',
                title: 'Manage resources',
                icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-4 h-4 mr-3"><path fill="#666" d="M128 128V96C128 42.98 170.1 0 224 0C277 0 320 42.98 320 96V128H400C426.5 128 448 149.5 448 176V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V176C0 149.5 21.49 128 48 128H128zM160 128H288V96C288 60.65 259.3 32 224 32C188.7 32 160 60.65 160 96V128zM48 160C39.16 160 32 167.2 32 176V416C32 451.3 60.65 480 96 480H352C387.3 480 416 451.3 416 416V176C416 167.2 408.8 160 400 160H320V240C320 248.8 312.8 256 304 256C295.2 256 288 248.8 288 240V160H160V240C160 248.8 152.8 256 144 256C135.2 256 128 248.8 128 240V160H48z"/></svg>,
                onClick: () => navigate('/learn/resources'),
              },
            ]}
          />
        )}
      </div>
    </AssessmentsSupervisor>
  )
}

type ChapterProps = {
  appData: AppState;
  userData: GetUserAndActiveCourseResponse;
  subData: GetCurrentSubscriptionRes;
  currentChapterId: string;
  chaptersCount: number;
  rtlDir?: React.CSSProperties | {} | undefined;
  showPlaceholderAction: boolean;
  setShowPlaceholderAction: React.Dispatch<React.SetStateAction<boolean>>;
  magicActionCreationEnabled?: boolean;
}

const Chapter = ({ appData, userData, subData, currentChapterId, chaptersCount, rtlDir, showPlaceholderAction, magicActionCreationEnabled, setShowPlaceholderAction }: ChapterProps) => {
  const navigate = useNavigate()
  const { subscription, course } = userData
  const [companyId, courseId] = appData.appState.currentCompanyIdCourseId.split('-') || ['', '']
  const isEditing = appData.appState.isEditing
  const isEditor = subData.user.editorCourses.includes(courseId)
  const courseCompleted = !!(subscription.progress?.completion && subscription.progress.completion >= 100)
  const assessmentCompleted = subscription.progress && subscription.progress.assessmentsCompleted || false
  const assessmentGrade = subscription.progress && subscription.progress.assessmentsGrade || 0
  const assessmentPassed = assessmentGrade >= course.passingGrade
  const canRetakeAssessments = subscription.progress && course.canRetakeActionAssessments && ((subscription.progress.assessmentsRetakeNum || 0) < course.maxActionAssessmentsRetakes)
  const distributionParams = subscription.company?.subsDistributionParams.find(c => c.courseId === courseId) || subsDistributionParamsFactory()
  const manualReviewsSkipEndorsment = !!distributionParams.manualReviewsSkipEndorsment

  const showMagic = () => {
    setShowPlaceholderAction(true)
    const closeModal = showModal2({
      onlyContent: true,
      className: 'post',
      scrollable: true,
      component: <MagicCreator typeOfCreator="action" courseId={course.id} chapterId={currentChapterId} closeModal={()=> closeModal.close()} />,
      onHideModal: () => setShowPlaceholderAction(false),
      disableClickOutside: true,
    })
  }

  const creatingActionInsideTheChapter = userData.course.creatingAIChapterContent || {}
  const isActionCreatingInsideChapter = Object.keys(creatingActionInsideTheChapter).some(key =>
    key === currentChapterId && creatingActionInsideTheChapter[key] === true
  )

  if (course.type === 'scorm') {
    return (
      <ScormProgram companyId={subscription.companyId} course={course} userProfile={subData.user.profile} />
    )
  }

  if (chaptersCount <= 0) {
    return (
      <NoSessionAlert
        isEditing={appData.appState.isEditing}
      />
    )
  }

  return (
    <>
      {!isEditing && assessmentCompleted && (
        <AssessmentsCompletedDialog
          userId={appData.appState.loggedInAs.uid}
          isEditor={isEditor}
          companyId={companyId}
          course={userData.course}
          chapterId={currentChapterId}
          assessmentPassed={assessmentPassed}
          canRetakeAssessments={canRetakeAssessments || false}
        />
      )}

      {!isEditing && courseCompleted && course.manualReviews && (
        <ManualReviewCourseCompleted
          companyId={companyId}
          courseId={courseId}
          userId={subData.user.id}
          review={subscription.progress?.review || null}
          manualReviewsSkipEndorsment={manualReviewsSkipEndorsment}
        />
      )}

      <div className="stick-container relative p-8 md:p-16 bg-white md:rounded-md shadow-md">
        {!isEditing && (
          <div className="flex items-start justify-between lg:hidden absolute top-4 left-0 w-full px-6">
            <ResetStudy side={false} companyId={companyId} courseId={courseId} userId={subData.user.id} email={subData.user.email} />

            <button className="text-center text-lightgray" onClick={() => navigate('/learn/notes')} >
              <i className="icon icon-note-light text-xl" />
              <div className="text-sm">Notes</div>
            </button>
          </div>
        )}

        <AssessmentCounter2 chapterId={currentChapterId} isAction={false} />

        <ChapterDetails
          appData={appData}
          userData={userData}
          subData={subData}
          chapterId={currentChapterId}
          rtlDir={rtlDir}
          isEditor={isEditor}
        />
      </div>

      <div className="mt-4 current-session new">
        <ChapterActions
          appData={appData}
          userData={userData}
          subData={subData}
          chapterId={currentChapterId}
        />
      {showPlaceholderAction && <ActionItemPlaceholder placeholderText={intl.get('magic_action_being_created')} />}
      {isActionCreatingInsideChapter && <ActionItemLoader isEditing={isEditing} />}
      </div>

      {!isEditing && (
        <NextChapterButton
          appData={appData}
          currentChapterId={currentChapterId}
          isEditor={isEditor}
        />
      )}

      {isEditor && isEditing &&  (
        <div className="flex flex-row items-center justify-center my-auto space-x-2">
          <NewActionButton
            appData={appData}
            currentChapterId={currentChapterId}
            manualReviews={userData.course.manualReviews}
            features={subData.user.features}
            disabled={isActionCreatingInsideChapter || !!userData.course.creatingAIContent}
            showMagic={showMagic}
          />
          {magicActionCreationEnabled && <Button
            text={''}
            disabled={isActionCreatingInsideChapter || !!userData.course.creatingAIContent}
            type="magic"
            size="small"
            className="mt-4 flex justify-center mb-8 md:mb-0"
            onClick={showMagic}
            svgIcon={<MagicStarsIcon />}
          />}

        </div>
      )}
    </>
  )
}

const ChapterDetails = ({ appData, subData, userData, chapterId, rtlDir, isEditor }: { appData: AppState, subData: GetCurrentSubscriptionRes, userData: GetUserAndActiveCourseResponse, chapterId: string|null, rtlDir?: React.CSSProperties | {} | undefined; isEditor?: boolean }) => {
  const navigate = useNavigate()
  const [companyId, courseId] = appData.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: chapterData } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: subData.user.editorCourses.includes(courseId) },
  })

  const { data: studyData } = useQuery<GQLStudyForSubscriptionResponse, GQLStudyForSubscriptionVariables>(getStudyForSubscriptionQuery, {
    skip: !appData?.appState.loggedInAs.uid,
    variables: { companyId, courseId, userId: appData?.appState.loggedInAs.uid || '' },
    fetchPolicy: 'cache-only',
  })

  const [updateChapter] = useMutation(updateChapterMutation)

  useEffect(() => {
    document.addEventListener('click', handleReferenceClick)
    return () => document.removeEventListener('click', handleReferenceClick)
  }, [chapterId])

  if (!chapterData) return <div>Loading...</div>

  const orderedChapters = chapterData.chapters.slice().sort((a, b) => a.order - b.order)
  const chapter = orderedChapters.find(c => c.id === chapterId)
  const prevChapter = orderedChapters.find(c => c.order === (chapter?.order || 1) - 1)
  const nextChapter = orderedChapters.find(c => c.order > (chapter?.order || 1))
  const isEditing = appData.appState.isEditing

  if (!chapter) return <div>What?</div>

  const update = (field, value) => {
    updateChapter({
      variables: {
        companyId,
        courseId: chapter.courseId,
        chapterId: chapter.id,
        chapterUpdate: {
          [field]: typeof value === 'object' ? omit(['__typename'], value) : value,
        },
      },
    })
    const d = client.readQuery<GetChaptersForCourseRes>({
      query: getChaptersForCourseQuery,
      variables: {
        companyId,
        courseId: chapter.courseId,
        returnAllAssessments: true,
      },
    })
    if (!d) return
    const index = d.chapters.findIndex(c => c.id === chapter.id)
    if (index !== -1) {
      client.writeQuery({
        query: getChaptersForCourseQuery,
        variables: {
          companyId,
          courseId: chapter.courseId,
          returnAllAssessments: true,
        },
        data: produce(d, (draft) => {
          draft.chapters[index][field] = value
        }),
      })
    }
  }

  const handleReferenceClick = (e) => {
    const target = e.target
    if (target && target.dataset && target.dataset.reference) {
      e.preventDefault()
      navigate(`${target.dataset.reference}/r`)
    }
  }

  const [studyAugmentMatch] = chapter.description && chapter.description.match(/{{user:(qa|answer):([^\:\}]+):([^\:\}]+?)}}/gi) || []
  const [src, prop, cId, actionId] = studyAugmentMatch && studyAugmentMatch.replace(/[{}]/gi, '').split(':') || []
  const chapterFromAugmentation = orderedChapters.find(c => c.id === cId)

  let augmentedDescription = chapter.description || ''
  if (studyData) {
    augmentedDescription = augmentStudy(augmentedDescription)(studyData?.study)(chapterFromAugmentation?.order || 0)
  }

  return (
    <>
      <ChapterOrder
        order={chapter.order}
        courseTitle={userData.course.title}
        isEditor={isEditor}
      />

      <ChapterTitle
        title={chapter.title}
        chapterId={chapter.id}
        isEditing={isEditing}
        update={v => update('title', v)}
      />

      <ChapterImage
        key={chapter.imageUrl || chapter.videoUrl || chapter.id}
        courseId={courseId}
        imageUrl={chapter.imageUrl}
        videoUrl={chapter.videoUrl}
        videoUrl_720p={chapter.videoUrl_720p}
        isEditing={isEditing}
        allowMediaDownload={userData.course.allowMediaDownload}
        update={update}
      />

      <ChapterDescription
        greetingsDisabled={userData.subscription.company?.greetingsDisabled || false}
        firstName={subData.user.profile.firstName || ''}
        description={appData.appState.isEditing ? chapter.description || '' : augmentedDescription}
        notes={userData.subscription.progress?.notes || ''}
        companyId={companyId}
        courseId={courseId}
        chapterId={chapter.id}
        isEditing={isEditing}
        update={v => update('description', v)}
        rtlDir={rtlDir}
      />

      {isEditing && (
        <Additional
          key={`details-${chapter.id}`}
          chapter={chapter}
          prevChapter={prevChapter}
          nextChapter={nextChapter}
          features={subData.user.features}
          update={update}
        />
      )}
    </>
  )
}

const ChapterActions = ({ appData, userData, subData, chapterId }: { appData: AppState, userData: GetUserAndActiveCourseResponse, subData: GetCurrentSubscriptionRes, chapterId: string }) => {
  const navigate = useNavigate()
  const [companyId, courseId] = appData.appState.currentCompanyIdCourseId.split('-') || ['', '']

  const { data: chapterData } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, {
    skip: !appData,
    variables: { companyId, courseId, returnAllAssessments: subData.user.editorCourses.includes(courseId) },
  })

  const { data: engagementData } = useQuery<GetEngagementsForSubscriptionRes, GetEngagementsForSubscriptionVars>(getEngagementsForSubscriptionQuery, {
    skip: !appData,
    variables: { companyId, courseId, userId: appData.appState.loggedInAs.uid || '' },
  })

  const { data: studyData } = useQuery<GQLStudyForSubscriptionResponse, GQLStudyForSubscriptionVariables>(getStudyForSubscriptionQuery, {
    skip: !appData?.appState.loggedInAs.uid,
    variables: { companyId, courseId, userId: appData?.appState.loggedInAs.uid || '' },
    fetchPolicy: 'cache-only',
  })

  if (!engagementData || !studyData || !chapterData) return <div>Loading actions...</div>

  const { course, subscription } = userData
  const { user } = subData

  const chapter = chapterData.chapters.find(c => c.id === chapterId)
  if (!chapter) return <div>What?</div>

  const courseEngagement = subscription?.progress?.engagement || 0
  const chapterEngagement = engagementData.engagement.find(e => e.chapterId === chapter.id)
  const distributionParams = subscription.company?.subsDistributionParams.find(c => c.courseId === courseId) || subsDistributionParamsFactory()
  const hideAssessmentsAfterCompleted = !!distributionParams.hideAssessmentsAfterCompleted && !!subscription.progress?.assessmentsCompleted
  const isExpired = isCourseExpired({ absoluteDeadline: distributionParams.absoluteDeadline, startedAt: subscription.progress?.startedAt || 0, relativeDeadline: distributionParams.relativeDeadline })
  const expiryDate = distributionParams.absoluteDeadline
    ? localeFormat(distributionParams.absoluteDeadline, 'PP')
    : distributionParams.relativeDeadline
      ? localeFormat(((subscription.progress?.startedAt || 0) + (Math.min(distributionParams.relativeDeadline, 9999) || 0) * 24 * 3600 * 1000), 'PP')
      : null

  const actions = chapter?.actions || []
  const allActions = chapterData.chapters.reduce((a: ActionType[], c) => {
    return a.concat(c.actions)
  }, [])
  const assessmentsNum = allActions.filter(a => a.contentType === 'assessment' && !a.invisible).length
  const assessmentsCompleted = allAssessmentsCompleted(chapterData.chapters, subscription.companyId, courseId, user.id)

  return <EnsureAssessmentsStartedIfNeeded
    chapterId={chapterId}
  >
    <ActionList
      user={user}
      app={appData}
      companyId={subscription?.company?.id || ''}
      actions={actions}
      progress={subscription.progress}
      course={course}
      study={studyData.study}
      hideAssessmentsAfterCompleted={hideAssessmentsAfterCompleted}
      isSequential={chapter.isSequential}
      engagement={chapterEngagement?.engagement || 0}
      onClick={(a) => {
        if (isExpired) {
          return showModal({
            title: intl.get('error_title_oops'),
            content: intl.get('course_expired_text', { 0: expiryDate }),
            primaryText: intl.get('ok'),
            secondaryButton: false,
          })
        }

        if (a.contentType === 'certificate') {
          if (!appData.appState.isEditing && !checkCertificate({
            assessmentsCompleted,
            assessmentsNum,
            courseEngagement,
            subscription,
            companyId: companyId,
            course,
            chapterId: chapter.id,
            navigate: navigate,
          })) return
        }

        const el = document.getElementById('learn-main')
        el?.scrollTo && el.scrollTo(0, 0)

        if (a.contentType === 'external' && a.contentUrl && !appData.appState.isEditing) {
          const win = window.open(a.contentUrl)
          if (win) win.opener = null
        }

        navigate(`/learn/${chapter.id}/${a.id}`)
      }}
    />
  </EnsureAssessmentsStartedIfNeeded>
}

export const getCurrentChapterId = ({ chapters, courseId, stateChapterId, matchChapterId, isEditing, isEditor, currentDay, chaptersUnlocked }) => {
  const today = getDaysFromEpoch2(new Date())

  const enabledChapters = chapters.filter(c => !c.disabledUntilDay || c.disabledUntilDay <= today)
  const usableChapters = isEditing || isEditor
    ? chapters
    : enabledChapters.length > 0
      ? enabledChapters
      : chapters

  let localCurrentChapter: string = JSON.parse(localStorage.getItem('currentChapters') || '{}')[courseId]
  if (!usableChapters.some(c => c.id === localCurrentChapter)) {
    localCurrentChapter = ''
    const newLocalChapters = JSON.parse(localStorage.getItem('currentChapters') || '{}')
    delete newLocalChapters[courseId]
    localStorage.setItem('currentChapters', JSON.stringify(newLocalChapters))
  }

  const firstChapter: ChapterType|undefined = chapters.find(c => c.order === 1)
  const courseCurrentChapter = usableChapters.find(c => c.order === (Math.min(currentDay || 0, chapters.length) || 1))
  const currentChapter = stateChapterId

  const setFirstChapterId = () => {
    return chaptersUnlocked === 'unlocked'
      ? firstChapter && firstChapter.id       // current chapter is first chapter in course
      : courseCurrentChapter                  // if course is daily or streak
        ? courseCurrentChapter.id
          ? courseCurrentChapter.id           // current chapter is chapter from progress (if any progress)
          : firstChapter && firstChapter.id   // else current chapter is first chapter
        : firstChapter && firstChapter.id
  }

  const activeChapterId: string = (matchChapterId && matchChapterId && !/current/i.test(matchChapterId) && usableChapters.some(c => c.id === matchChapterId))
    ? matchChapterId    // current chapter is chapter from url (if it exists)
    : localCurrentChapter
      ? localCurrentChapter       // current chapter is chapter set locally for active course (localStorage)
      : currentChapter            // if we have an active chapter in apollo cache
        ? currentChapter && usableChapters.some(c => c.id === currentChapter)
          ? currentChapter        // current chapter is chapter set in local apollo cache (if it exists)
          : chapters.length > 0
            ? setFirstChapterId()      // if it doesn't exist, current chapter is first chapter
            : ''
        : setFirstChapterId()

  return activeChapterId
}

const ResetStudy = ({ companyId, courseId, userId, email, side }: { companyId: string, courseId: string, userId: string, email: string, side: boolean }) => {
  const confirmReset = () => {
    showModal({
      title: intl.get('be_careful'),
      component: intl.getHTML('remove_user_study', { email }),
      primaryText: intl.get('global_delete'),
      primaryAction: () => resetUserStudy({ companyId, courseId, userId }),
      destructive: true,
      imageUrl: '/images/ppt-upsell.png',
    })
  }

  if (side) {
    return (
      <SideIcon id="delete-chapter-tip" text={intl.get('dialog_reset_answers')} icon="icon-eraser-light" onClick={confirmReset}/>
    )
  }

  return (
    <button className="text-center w-16 text-lightgray" data-test="reset-answer" onClick={confirmReset} >
      <i className="icon icon-eraser-light text-xl"/>
      <div className="text-sm leading-4">{intl.get('dialog_reset_answers')}</div>
    </button>
  )
}

export const SideIcon = ({ id, text, icon, onClick }) => {
  const pulseAnimation = id === 'resources-tip' ? 'icon-animate' : ''
  return (
    <Tippy content={text} placement="left">
      <button className={`flex items-center justify-center icon ${icon} ${pulseAnimation} w-14 h-14 bg-white rounded-full shadow-md text-xl hover:text-coral`} aria-label={text} onClick={onClick}/>
    </Tippy>
  )
}

const EnsureAssessmentsStartedIfNeeded = (props: {
  chapterId: string,
  children,
}) => {
  const {chapterId, children} = props
  const ctx = useContext(AssessmentsSupervisorCtx)

  if (!ctx || !ctx.initialized) {

    return (
      <div className="flex justify-center items-center">
        <MiniLoader />
      </div>
    )
  }

  const status = ctx.chapters.get(chapterId)

  if (!status) {
    return children
  }

  if (status.status === 'nolimit') {
    return children
  }

  if (status.status === 'not_started') {
    const timeLimit = status.timeLimit / 60000
    return (
      <div className="px-4 sm:px-0">
        <button
          className={'bg-gradient-to-r from-[#5EC3C5] to-[#0f52ff80] rounded-md p-5 text-center leading-loose shadow w-full cursor-pointer'}
          disabled={false}
          onClick={() => ctx.startForChapter(chapterId).then(() => {
            showModal({
              title: intl.get('global_hint'),
              content: 'If you don\'t see actions appear after a couple of seconds please reload the page.',
              secondaryButton: false,
            })
          }).catch((err) => {
            logger.error('Error while starting time limited assessment session', err)
            showModal({
              title: intl.get('error_title_generic'),
              content: err.message,
              secondaryButton: false,
            })
          })}>
          <div className="flex justify-between mb-4">
            <div className="text-white text-left">
              <div className="text-2xl">{intl.get('course_assessments')}</div>
              <div className="my-2 leading-6">
                {intl.get('timed_assessments_description')}&nbsp;
                <strong className="text-black">{intl.get('timed_assessments_minutes', { timed_assessments_minutes: timeLimit })}</strong>
              </div>
            </div>
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
              <path d="M49.5 0H10.5C4.8 0 0 4.8 0 10.5V49.5C0 55.2 4.8 60 10.5 60H42L60 42V10.5C60 4.8 55.2 0 49.5 0ZM54 39H49.5C43.8 39 39 43.8 39 49.5V54H11.4C8.4 54 6 51.6 6 48.6V11.4C6 8.4 8.4 6 11.4 6H48.9C51.9 6 54.3 8.4 54.3 11.4V39M39.6 18.6L44.1 23.1L26.1 41.1L15.6 30.6L20.1 26.1L26.1 32.1L39.6 18.6Z" fill="white" fill-opacity="0.75"/>
            </svg>
          </div>

          <strong className="bg-white py-3 px-6 rounded-md">{intl.get('timed_assessments_title')}</strong><br/>
        </button>
      </div>
    )
  }

  if (status.status === 'starting') {
    return (
      <button
        className="bg-white py-5 text-center leading-loose shadow w-full cursor-disabled"
        disabled={true}>
        <div className="flex justify-center items-center">
          <MiniLoader />
        </div>
      </button>
    )
  }

  if (status.status === 'started' || status.status === 'ended') {
    return children
  }

  assertNever(status)
}
